import React from 'react';
import LogRocket from 'logrocket';
import { useAuth0 } from '@auth0/auth0-react';

export const useAuth0Wrapper = () => {
    const values = useAuth0();
    if (values.isAuthenticated) {
        console.log('identifying --');

        // identify logrocket session
        LogRocket.identify(values.user.sub, {
            email: values.user.email,
            name: values.user.name,
        });
    }

    return values;
};
