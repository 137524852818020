import React from 'react'
import { Spinner } from 'reactstrap'

const Loading = () => (
    <div style={{ textAlign: 'center', padding: '2rem', height: '4rem' }}>
        <Spinner color="info" />
    </div>
)

export default Loading
