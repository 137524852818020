export const upsertSessionStorage = (itemKey, upsertValue) => {
    // NOTE:
    // This only persists within a singular tab. The `window.sessionStorage`
    // doesn't seem to persist in multiple tabs. This results in a behavior
    // where if a user alt-clicks many links, each link will have their own
    // clean `window.sessionStorage`, resulting in a modal pop-up.
    if (typeof window !== 'undefined' && window && window.sessionStorage) {
        // try to get the cache key from the session
        // if it's not there, then we pass in a new one
        const value = window.sessionStorage.getItem(itemKey);
        if (value) {
            return value;
        } else {
            if (upsertValue) {
                window.sessionStorage.setItem(itemKey, upsertValue);
                return upsertValue;
            }
        }
    }
};
