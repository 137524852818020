import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const FirstTimeModal = (props) => {
    const closeModal = () => {
        props.setShowModal(false);
        props.onUnmount();
    };
    return (
        <Modal isOpen={props.isOpen} toggle={closeModal}>
            <ModalHeader toggle={closeModal}>
                Features for signed up users
            </ModalHeader>
            <ModalBody>
                <img
                    src={
                        'https://i.gyazo.com/1c3a527ddb1c3c881bf8f5ac7941ee9b.gif'
                    }
                />
                <div>
                    <small>
                        As a user, you'll be able to save fragrance calculations
                        you've created. Head over to our{' '}
                        <a href="/candles/scent-calculator/">
                            V2 fragrance calculator
                        </a>{' '}
                        to start managing your fragrance calculations.
                    </small>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={closeModal}>
                    Acknowledge
                </Button>
            </ModalFooter>
        </Modal>
    );
};
