import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import Loading from '../../custom/components/Loading';
import { useAuth0Wrapper } from './hooks';
import { upsertSessionStorage } from '../upsertSessionStorage';
import { FirstTimeModal } from './FirstTimeModal';

export const SEEN_FIRST_MODAL_KEY = 'seen_first_modal';

export const LoginButton = () => {
    // There should only be 1 Auth0Provider -- else any resulting
    // hook calls won't return accurate responses.

    // https://auth0.com/docs/quickstart/spa/vanillajs/01-login
    // this is to follow with hte auth0 doc
    const { isAuthenticated, user, isLoading, loginWithRedirect, logout } =
        useAuth0Wrapper();
    const seenFirstModal = upsertSessionStorage(SEEN_FIRST_MODAL_KEY);
    const [showModal, setShowModal] = React.useState(
        // if not found, then show modal
        !(seenFirstModal === 'true')
    );

    const onUnmount = () => {
        console.log('setting seen session storage key');
        // set the session storage to be true on unmount
        upsertSessionStorage(SEEN_FIRST_MODAL_KEY, true);
    };

    let baseComponent = null;

    if (isAuthenticated) {
        baseComponent = (
            <Row>
                <FirstTimeModal
                    isOpen={showModal}
                    setShowModal={setShowModal}
                    onUnmount={onUnmount}
                />
                <Col xs="8">
                    <div>
                        <small>Logged in as: {user.name}</small>
                    </div>
                </Col>
                <Col xs="4">
                    <Button onClick={logout} size="sm">
                        Logout
                    </Button>
                </Col>
            </Row>
        );
    } else if (isLoading) {
        return <Loading />;
    } else {
        baseComponent = (
            <Button onClick={loginWithRedirect} color="primary">
                Login
            </Button>
        );
    }

    return baseComponent;
};

export default LoginButton;
