import React from 'react';
import {Link} from 'gatsby';

import {rhythm, scale} from '../utils/typography';
import LoginButton from './LoginButton';
import {Row, Col} from 'reactstrap';

const Layout = ({location, title, children}) => {
    const rootPath = `${__PATH_PREFIX__}/`;
    let header;
    const largeHeaderPages = [
        rootPath,
        '/2',
        '/3',
        '/4',
        // manually increment for now.
    ];

    if (largeHeaderPages.includes(location.pathname)) {
        header = (
            <h1
                style={{
                    ...scale(1.5),
                    marginBottom: rhythm(1.5),
                    marginTop: 0,
                    fontFamily: `athelas, serif`,
                }}
            >
                <Link
                    style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `inherit`,
                    }}
                    to={`/`}
                >
                    {title}
                </Link>
            </h1>
        );
    } else {
        header = (
            <h3
                style={{
                    fontFamily: `athelas, serif`,
                    marginTop: 0,
                }}
            >
                <Link
                    style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `inherit`,
                    }}
                    to={`/`}
                >
                    {title}
                </Link>
            </h3>
        );
    }
    return (
        <div>
            {
                // this will be the __left__ side of the text. we can probably do some interesting stuff here.
            }
            <div
                style={{
                    marginLeft: `auto`,
                    marginRight: `auto`,
                    maxWidth: rhythm(30), // this controls the width of the main page.
                    padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                }}
            >
                <Row>
                    <Col md="9">
                        <header>{header}</header>
                    </Col>
                    <Col md="3">
                        <LoginButton />
                    </Col>
                </Row>
                <main>{children}</main>
                {/*
          comment out the footer for now.
          <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer>
          */}
            </div>
        </div>
    );
};

export default Layout;
